import { Routes } from '@angular/router';

import { canActivateApp } from './core/guards/auth.guard';
import { canActivateRoute } from '@core/guards/permission.guard';

export const routes: Routes = [
  {
    path: 'auth',
    loadComponent: () => import('./feature/auth/auth.component').then((m) => m.AuthComponent),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./feature/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [canActivateApp],
  },
  {
    path: 'partners',
    loadChildren: () => import('./feature/partners/partners.module').then((m) => m.PartnersModule),
    canActivate: [canActivateRoute],
    data: { scope: 'partner' },
  },
  {
    path: 'users',
    loadChildren: () => import('./feature/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'lambda',
    loadChildren: () => import('./feature/lambda/lambda.module').then(m => m.LambdaModule)
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
];
