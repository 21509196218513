import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable, tap } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class ApiInterceptor implements HttpInterceptor {
  auth = inject(AuthService);
  snack = inject(MatSnackBar);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authReq = req.clone({
      headers: req.headers.set('Authorization', this.auth.token),
    });
    // this needs to be refactored
    return next.handle(authReq).pipe(tap({
      error: (error) => {
        if(error.status === 401){
          this.auth.login();
        } else {
          const message = error.error.message || error.message;
          this.snack.open(message, 'Dismiss');
        }
      }
    }));
  }
}
